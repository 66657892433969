import { NavBar, Icon, Search, Toast, DropdownMenu, DropdownItem, List, PullRefresh, Image } from 'vant'
// import loginPop from '../../../../cmps/loginPop/loginPop'
export default {
  name: 'tickets',
  components:{
    // loginPop,
    'van-pull-refresh': PullRefresh,
    'van-list': List,
    'van-image': Image,
    'van-nav-bar': NavBar,
    'van-icon': Icon,
    'van-search': Search,
    'van-dropdown-menu': DropdownMenu,
    'van-dropdown-item': DropdownItem,
  },
  data() {
    return {
      apis: {
        hots: '/recommend/list', //获取热门推荐列表
        scenics: '/seller/list', //获取景点门票列表
        citys: '/baseArea/getAllArea', //获取区县及热门城市
        queryPriceCalendarBySellerId: '/commodity/queryPriceCalendarBySellerId', //查询是否有价格日历
        queryCommodityTypeName: '/seller/querySellerTypeName', //查询title
      },
      titleName:'',
      citys: [], //城市列表
      hots: [], //热门推荐列表
      scenics: [], //景点门票列表
      params: { //门票搜索条件
        sellerTypeCode: 'JQ', //酒店固定类型编码
        areaId: null, //地区ID
        name: '', //关键字，目的地，名称
        pageIndex: 1, //当前页码
        pageSize: 10, //每页显示记录数
        longitude: null, //游客经度
        latitude: null, //游客纬度
      },
      refreshing: false, //是否刷新
      loading: true, //是否加载中
      finished: false, //数据是否已经加载完成
      dropdownValue: { //下拉菜单的值
        tag: 'none',
        priceSort: 'none',
        starSort: 'none'
      },
      tags: [ //商家标签
        { text: '按类型', value: 'none' }, //此值和后台查询景点门票的值保持一致
        { text: '新款商品', value: '新款商品' },
        { text: '活动商品', value: '活动商品' }
      ],
      priceSort: [
        { text: '按价格', value: 'none' },
        { text: '价格升序', value: 'showPrice-asc' },
        { text: '价格降序', value: 'showPrice-desc' },
      ],
      starSort: [
        { text: '按评分', value: 'none' },
        { text: '评分升序', value: 'star-asc' },
        { text: '评分降序', value: 'star-desc' },
      ],
      token: '',//判断是否登录
      isShowLoginPop:false,
    }
  },
  created(){
    this.getShareInfo(
      this.$global.shareTitle,
      this.$global.visitUrl,
      this.$global.shareImage,
      this.$global.shareIntro,
    );
    if(this.$route.query.sellerTypeCode){
      localStorage.setItem('sellerTypeCodeT',this.$route.query.sellerTypeCode);
      this.params.sellerTypeCode = this.$route.query.sellerTypeCode
    }
    if(localStorage.getItem('sellerTypeCodeT')){
      this.params.sellerTypeCode = localStorage.getItem('sellerTypeCodeT')
    }
  },
  mounted() {
    let me = this;
    me.loadLocation();
    me.loadHots();
    me.getName();
    localStorage.setItem('page','tickets');
  },
  methods: {
    getName(){
      let me = this;
      me.$get({
        url: me.apis.queryCommodityTypeName,
        params: {
          code: this.params.sellerTypeCode
        }
      }).then(rsp => {
        this.titleName = rsp.name;
      });
    },
    revert() {
      this.$router.go(-1);
    },
    loadLocation() { //获取经纬度
      this.loadCitys();
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    loadHots() { //获取热门推荐列表
      const me = this;
      me.$get({
        url: me.apis.hots,
        params: {
          type: 2 //固定值2，获取热门景点
        }
      }).then(rsp => {
        me.hots = rsp.data;
        rsp.data.map((item,index) => {
          me.$get({
            url: me.apis.queryPriceCalendarBySellerId,
            params: {
              sellerId: item.bizId
            }
          }).then(rsp => {
            me.hots[index].priceCalendarCount = rsp;
          });
        })
      });
    },
    loadScenics() { //获取全部景点门票列表
      const me = this;
      me.params.token =  me.$global.token;
      let locations = JSON.parse(localStorage.getItem('map'));
      me.params.longitude =  locations ? locations.position.lng : null;
      me.params.latitude =  locations ? locations.position.lat : null;
      console.log(me.params)
      me.loading = true;
      me.$get({
        url: me.apis.scenics,
        params: me.params
      }).then(rsp => {
        console.log(rsp);
        me.loading = false;
        if (me.refreshing) {
          me.scenics = [];
          me.refreshing = false;
        }
        var list = rsp.data;
        console.log(list);
        if (list && list.length > 0) {
          me.scenics = me.scenics.concat(list);
        }
        if (!list || list.length < me.params.pageSize) {
          me.finished = true;
        }
      });
    },
    loadCitys() { //获取热门城市
      const me = this;
      me.citys = [];
      me.$get({
        url: me.apis.citys,
        params: {
          adCode: 'qy-ls'
        }
      }).then(rsp => {
        if (rsp && rsp.length > 0) {
          var city = null;
          me.cityScr(city, rsp);
          /*return;
          if (this.$global.modelState) {
            try {
              city = window.android.getCity();
              city = city ? JSON.parse(city) : null;
              me.params.areaId =  city.areaId;
            } catch (e) {}

          } else {
            this.$iosMethod(function(bridge) {
              bridge.callHandler('getCity', null, function(data) {
                city = data;
                city = city ? JSON.parse(city) : null;
                if (city.areaId) {
                  me.params.areaId =  city.areaId;
                }
              })
            })
          }*/
        }

      });
    },
    cityScr(city, rsp) {
      const me = this;
      console.log('我执行了');
      me.citys.push({
        text: '全部',
        value: ''
      });
      me.params.areaId =  '';
      for (let i = 0; i < rsp.length; i++) {
        me.citys.push({
          text: rsp[i].name,
          value: rsp[i].areaId
        });
      }
      me.loadScenics()
    },
    textFunc(text) { //   内容2行显示
      // let num = '';
      // text.length>30?(num=text.slice(0,30)+'...'):(num=text)
      // return num

    },
    tagChange(v) { //筛选类型改变时执行
      const me = this;
      me.refreshing = true;
      if (v != 'none') {
        me.params.tags =  v;
      } else {
        me.params.tags =  null;
      }
      me.params.pageIndex =  1;
      me.loadScenics();
    },
    sortChange(v) { //排序方式改变时执行
      const me = this;
      me.refreshing = true;
      if (v == 'showPrice-asc' || v == 'showPrice-desc' || v == 'star-asc' || v == 'star-desc') {
        let sorts = v.split('-');
        me.params.sortName =  sorts[0];
        me.params.sortOrder =  sorts[1];
        if (sorts[0] == 'showPrice') {
          me.params.starSort =  'none';
        } else {
          me.dropdownValue.priceSort =  'none';
        }
      } else {
        me.params.sortName =  null;
        me.params.sortOrder =  null;
        me.dropdownValue.priceSort =  'none';
        me.params.starSort =  'none';

      }
      me.params.pageIndex = 1;
      me.loadScenics();
    },
    //跳转到详情界面
    toDetail(key,priceCalendarCount) {
      // 根据token判断是否跳转登录
      this.$router.push({
        path:'/tiketDetails/' + key,
        query:{
          priceCalendarCount:priceCalendarCount,
        }
      });
    },
    search() { //查询
      this.refreshing = true;
      this.onRefresh();
    },
    onRefresh() { //下拉刷新
      const me = this;
      me.finished = false;
      me.params.pageIndex =  1;
      me.loadScenics();
    },
    onLoad() { //上拉加载
      const me = this;
      let page = me.params.pageIndex;
      page = page ? page + 1 : 1;
      me.params.pageIndex =  page;
      me.loadScenics();

    }
  }
}
